import styles from 'components/shared/alternative-hero/alternative-hero.module.scss';
import { Button } from 'components/shared/button';
import { Heading } from 'components/shared/heading';
import { HeroFrame } from 'components/shared/hero-frame';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import Arrow from 'svg/arrow.inline.svg';
import { app } from 'utils/urls';

// import styles from './azure-vs-k6-hero.module.scss';

export const Hero = () => {
  const { file: image } = useStaticQuery(graphql`
    query osHeroImageAndOsHeroImage {
      file(absolutePath: { regex: "/azure-hero.png/" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  return (
    <section className={styles.wrapper}>
      <div className={`container ${styles.inner}`}>
        <div className={'row'}>
          <div className={'col-lg-5 col-12'}>
            <Heading className={styles.title} tag={'h1'}>
              Load testing for engineering teams
            </Heading>
            <p className={styles.description}>
              Join thousands of developers and QA engineers, and enjoy a greater DX and flexibility with k6.
            </p>
            <div className={styles.buttonWrapper}>
              <Button
                className={styles.btn}
                tag={'a'}
                href={'https://grafana.com/auth/sign-up/create-user?src=k6io'}
                cursor
              >
                Create free account
              </Button>
              <Arrow className={styles.arrow} />
              <span className={styles.note}>500 VUh/month</span>
            </div>
            <p className={styles.demoLinkWrapper}>
              <a className={'link'} href={'https://go2.grafana.com/k6-contact.html'}>
                Schedule a Demo &gt;
              </a>
            </p>
          </div>
          <div className={styles.imageBlock}>
            <GatsbyImage
              image={getImage(image)}
              className={styles.integrationImage}
              imgStyle={{ objectFit: 'contain' }}
              alt=""
              loading="eager"
            />
          </div>
        </div>
      </div>

      <HeroFrame backgroundLabel={styles.frameWrapper} />
    </section>
  );
};
