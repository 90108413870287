import { Heading } from 'components/shared/heading';
import React from 'react';

import styles from './home-jmeter-upgrade.module.scss';
import animationVideo from './videos/animation.mp4';

export const JMeterUpgrade = () => (
  <section className={styles.wrapper}>
    <div className={'container'}>
      <div className={'row align-items-xl-center'}>
        <div
          className={'col-lg-6'}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            // eslint-disable-next-line max-len
            __html: `<video class="${styles.illustration}" src="${animationVideo}" autoPlay loop muted playsinline/>`, // Because React doesn't support attribute 'muted'. Nice, huh? https://github.com/facebook/react/issues/10389
          }}
        />
        <div className={'col-xl-5 col-lg-6'}>
          <Heading className={styles.title} tag={'h3'}>
            Still stuck with JMeter?
          </Heading>
          <p className={styles.description}>
            {/* eslint-disable-next-line max-len */}
            <i>
              &quot;The engineering effort of writing tests for just one of our microservices was going to be 2+
              Engineering weeks in JMeter, versus the ½ day it took us in k6!&quot;
            </i>
            <p>Alex Hibbitt, Site Reliability Engineer.</p>
          </p>
        </div>
      </div>
    </div>
  </section>
);
