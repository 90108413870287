import { Button } from 'components/shared/button';
import { Heading } from 'components/shared/heading';
import { Lines } from 'components/shared/lines';
import React from 'react';
import { docs } from 'utils/urls';

import styles from './azure-vs-k6-integrate.module.scss';
import DirectoryIcon from './svg/directory.inline.svg';
import BottomLine from './svg/line-bottom.inline.svg';
import TopLine from './svg/line-top.inline.svg';
import PiplinesIcon from './svg/piplines.inline.svg';
import TeamIcon from './svg/team.inline.svg';

export const Integrate = () => (
  <section className={styles.wrapper}>
    <div className={'container'}>
      <Heading className={styles.title} tag={'h3'}>
        k6 can integrate with the tools you rely on every day
      </Heading>
      <div className={'row justify-content-center'}>
        <div className={`col-md-4 col-sm-12 ${styles.itemWrapper}`}>
          <div className={styles.item}>
            <div className={styles.iconWrapper}>
              <TeamIcon className={styles.iconTeam} />
              <BottomLine className={styles.lineBottom} />
            </div>
            <Heading className={styles.itemTitle} tag={'h3'} size={'md'}>
              Microsoft Teams
            </Heading>
            <p className={styles.itemDescription}>
              Customize channel notifications to keep your team informed of performance issues.
            </p>
          </div>
        </div>
        <div className={`col-md-4 col-sm-12 ${styles.itemWrapper}`}>
          <div className={styles.item}>
            <div className={styles.iconWrapper}>
              <DirectoryIcon className={styles.iconDirectory} />
              <TopLine className={styles.lineTop} />
            </div>
            <Heading className={styles.itemTitle} tag={'h3'} size={'md'}>
              Azure Active Directory
            </Heading>
            <p className={styles.itemDescription}>Single sign-on to grant access to your performance projects.</p>
          </div>
        </div>
        <div className={`col-md-4 col-sm-12 ${styles.itemWrapper}`}>
          <div className={styles.item}>
            <div className={styles.iconWrapper}>
              <PiplinesIcon className={styles.iconPiplines} />
            </div>
            <Heading className={styles.itemTitle} tag={'h3'} size={'md'}>
              Azure Pipelines
            </Heading>
            <p className={styles.itemDescription}>
              Schedule and automate the execution of your tests using Azure Pipelines.
            </p>
          </div>
        </div>
      </div>
      <div className={'d-flex justify-content-center'}>
        <Button className={styles.button} tag={'a'} href={`${docs}/misc/integrations`} theme={'additional'} size={'sm'}>
          See more integrations
        </Button>
      </div>
    </div>

    <Lines imageNumber={5} className={styles.linesWrapper} />
  </section>
);
