import classNames from 'classnames';
import { Heading } from 'components/shared/heading';
import { Lines } from 'components/shared/lines';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import styles from './azure-vs-k6-table.module.scss';
import Arrow from './svg/arrow.inline.svg';
import AzureLogo from './svg/azure.inline.svg';
import CheckIcon from './svg/check.inline.svg';
import K6Logo from './svg/k6.inline.svg';

export const Table = () => {
  const {
    allTableDataJson: { edges: TABLE_DATA },
  } = useStaticQuery(graphql`
    query tableData {
      allTableDataJson {
        edges {
          node {
            title
            data {
              azure {
                type
                value
              }
              k6 {
                type
                value
              }
            }
          }
        }
      }
    }
  `);

  return (
    <section className={styles.wrapper}>
      <div className={'container'}>
        <Heading className={styles.title} tag={'h3'}>
          Switching from Azure DevOps Load Testing?
        </Heading>

        <div className={'row justify-content-center'}>
          <div className={'col-lg-8 col-md-12 col-sm-12'}>
            <div className={styles.tableWrapper}>
              <div className={styles.tableWrapperInner}>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th />
                      <th>
                        <span className={classNames(styles.logo, styles.azure)}>
                          <AzureLogo className={styles.logoIcon} />
                          Azure <span className={styles.logoLongName}>load testing</span>
                        </span>
                        <Arrow className={styles.logoArrow} />
                      </th>
                      <th>
                        <span className={classNames(styles.logo, styles.k6)}>
                          <K6Logo className={styles.logoIcon} />
                          Grafana Cloud <span className={styles.logoLongName}>k6</span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {TABLE_DATA.map(({ node: { title, data } }, i) => (
                      <tr key={`table-${i}`}>
                        <th>{title}</th>
                        {Object.keys(data).map((platform, j) => (
                          <td key={`platform-${j}`}>
                            {data[platform].type === 'text' && data[platform].value}
                            {data[platform].type === 'icon' && <CheckIcon />}
                            {data[platform].type === 'link' && (
                              <a href={data[platform].value} className={'link'}>
                                See more
                              </a>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/*
            <div className={styles.buttonsWrapper}>
              <Button
                className={styles.button}
                tag={'a'}
                href={'https://k6.io/blog/k6-as-alternative-for-azure-and-visual-studio-load-tests'}
                theme={'additional'}
                cursor
              >
                Read more on the blog
              </Button>
            </div>
            */}
          </div>
        </div>
      </div>

      <Lines imageNumber={2} className={styles.linesWrapperRightTop} />
      <Lines imageNumber={3} className={styles.linesWrapperLeft} />
      <Lines imageNumber={4} className={styles.linesWrapperRight} />
    </section>
  );
};
