import { Feedbacks } from 'components/blocks/feedbacks';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

export const AlternativeFeedbacks = (props) => {
  let { title, description } = props;
  const { noDescription } = props;

  title = title || 'Companies change for the better with k6';
  description = description || "Teams of all sizes switched to k6, and they aren't going back anytime soon.";
  if (noDescription) {
    description = null;
  }

  const {
    allLoadRunnerJson: { edges: DATA },
  } = useStaticQuery(graphql`
    query blazemeterFeedback {
      allLoadRunnerJson {
        edges {
          node {
            text
            author {
              name
              userinfo
            }
          }
        }
      }
    }
  `);

  return <Feedbacks title={title} description={description} feedbacks={DATA} />;
};
