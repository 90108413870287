import { SEO } from 'components/blocks/seo';
import { Features } from 'components/pages/azure-vs-k6/features';
import { Hero } from 'components/pages/azure-vs-k6/hero';
import { Integrate } from 'components/pages/azure-vs-k6/integrate';
import { Table } from 'components/pages/azure-vs-k6/table';
import { AlternativeFeedbacks } from 'components/shared/alternative-feedbacks';
import { CTA } from 'components/shared/cta';
import { JMeterUpgrade } from 'components/shared/jmeter-upgrade';
import { Partners } from 'components/shared/partners';
import { DefaultLayout } from 'layouts/default-layout';
import React from 'react';
import SEO_DATA from 'utils/seo-metadata';

const AzureVsK6 = () => (
  <DefaultLayout>
    <Hero />
    <Features />
    <Integrate />
    <AlternativeFeedbacks />
    <Table />
    <JMeterUpgrade />
    <Partners
      title={'Trusted and loved by more than 6000 companies for performance testing, including:'}
      onlyCustomers
    />
    <CTA mode="centered" />
  </DefaultLayout>
);

export default AzureVsK6;

export const Head = () => <SEO {...SEO_DATA['azure-vs-k6']} />;
