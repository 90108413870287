import { Heading } from 'components/shared/heading';
import { ItemCard } from 'components/shared/item-card';
import { Lines } from 'components/shared/lines';
import React from 'react';

import styles from './azure-vs-k6-features.module.scss';
import imageCloud from './svg/cloud.svg';
import imageTerminal from './svg/terminal.svg';

export const Features = () => (
  <section className={styles.wrapper}>
    <div className={`container ${styles.inner}`}>
      <div className={'row justify-content-center'}>
        <div className={'col-md-4 col-sm-12'}>
          <ItemCard to={'/open-source/'}>
            <div className={styles.itemContent}>
              <div>
                <Heading className={styles.itemTitle} tag={'h2'} size={'md'}>
                  k6 Open Source
                </Heading>
                <p className={styles.itemDescription}>Modern load testing tool built for developers.</p>
              </div>
              <img className={styles.itemImage} src={imageTerminal} alt={''} />
            </div>
            <div className={styles.itemFooter}>
              <span className={styles.itemLink}>Read more</span>
            </div>
          </ItemCard>
        </div>

        <div className={'col-md-4 col-sm-12'}>
          <ItemCard href={'https://grafana.com/products/cloud/k6/?src=k6io'}>
            <div className={styles.itemContent}>
              <div>
                <Heading className={styles.itemTitle} tag={'h2'} size={'md'}>
                  Grafana Cloud k6
                </Heading>
                <p className={styles.itemDescription}>Managed performance testing for engineering teams.</p>
              </div>
              <img className={styles.itemImage} src={imageCloud} alt={''} />
            </div>
            <div className={styles.itemFooter}>
              <span className={styles.itemLink}>Read more</span>
            </div>
          </ItemCard>
        </div>
      </div>
    </div>

    <Lines imageNumber={1} className={styles.linesWrapper} />
  </section>
);
